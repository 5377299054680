import React, { useMemo } from "react";
import { Edit, Trash2, Search, Filter } from "lucide-react";
const BeneficiaryList = ({
  beneficiaries,
  onEdit,
  onDelete,
  searchTerm,
  setSearchTerm,
  filterCropType,
  setFilterCropType,
}) => {
  const cropTypes = [
    { value: "wheat", label: "Wheat" },
    { value: "rice", label: "Rice" },
    { value: "maize", label: "Maize" },
    { value: "cotton", label: "Cotton" },
    { value: "sugarcane", label: "Sugarcane" },
  ];

  const filteredBeneficiaries = useMemo(() => {
    return beneficiaries.filter((b) => {
      const matchesSearch =
        b.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        b.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        b.phone.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesCropType =
        filterCropType === "" || b.cropType === filterCropType;

      return matchesSearch && matchesCropType;
    });
  }, [beneficiaries, searchTerm, filterCropType]);

  return (
    <div className="bg-white rounded-2xl shadow-lg border border-gray-100 p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Beneficiary List</h2>
        <div className="flex space-x-4">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Search beneficiaries..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <Search
              size={20}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            />
          </div>
          <div className="relative">
            <select
              value={filterCropType}
              onChange={(e) => setFilterCropType(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">All Crops</option>
              {cropTypes.map((crop) => (
                <option key={crop.value} value={crop.value}>
                  {crop.label}
                </option>
              ))}
            </select>
            <Filter
              size={20}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            />
          </div>
        </div>
      </div>

      {filteredBeneficiaries.length === 0 ? (
        <div className="text-center py-12 text-gray-500">
          {beneficiaries.length > 0
            ? "No beneficiaries match your search"
            : "No beneficiaries added yet"}
        </div>
      ) : (
        <div className="space-y-4">
          {filteredBeneficiaries.map((beneficiary) => (
            <div
              key={beneficiary.id}
              className="flex items-center justify-between bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors"
            >
              <div>
                <h3 className="font-semibold text-gray-800 text-lg">
                  {beneficiary.name}
                </h3>
                <p className="text-sm text-gray-600">
                  {beneficiary.email} | {beneficiary.phone}
                </p>
                <div className="mt-2 text-sm text-gray-500">
                  {beneficiary.landSize} acres | {beneficiary.cropType} |{" "}
                  <span className="font-medium text-green-600">
                    {beneficiary.status}
                  </span>
                </div>
              </div>
              <div className="flex space-x-2">
                <button
                  className="text-blue-500 hover:bg-blue-100 p-2 rounded-full transition-colors"
                  onClick={() => onEdit(beneficiary)}
                >
                  <Edit size={20} />
                </button>
                <button
                  className="text-red-500 hover:bg-red-100 p-2 rounded-full transition-colors"
                  onClick={() => onDelete(beneficiary.id)}
                >
                  <Trash2 size={20} />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default BeneficiaryList;
