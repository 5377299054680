import React, { useState, useCallback } from "react";
import {
  Send,
  MessageCircle,
  Inbox,
  CheckCircle2,
  XCircle,
} from "lucide-react";

function SMSPage() {
  const [smsData, setSmsData] = useState({
    recipients: "",
    message: "",
    group: "",
    recipientsList: [],
    sendMode: "sendAll", // Default to 'Send All'
  });
  const [sentMessages, setSentMessages] = useState([]);
  const [messageStatus, setMessageStatus] = useState({
    loading: false,
    success: false,
    error: null,
  });

  const simulateSMSSend = useCallback(() => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        Math.random() > 0.2 ? resolve() : reject(new Error("Network error"));
      }, 1500);
    });
  }, []);

  const handleChange = (e) => {
    setSmsData({ ...smsData, [e.target.name]: e.target.value });
  };

  const handleAddRecipient = (e) => {
    e.preventDefault();
    if (smsData.recipients.trim()) {
      setSmsData({
        ...smsData,
        recipients: "",
        recipientsList: [...smsData.recipientsList, smsData.recipients],
      });
    }
  };

  const handleRemoveRecipient = (index) => {
    const updatedRecipients = [...smsData.recipientsList];
    updatedRecipients.splice(index, 1);
    setSmsData({
      ...smsData,
      recipientsList: updatedRecipients,
    });
  };

  const handleSendSMS = async (e) => {
    e.preventDefault();
    setMessageStatus({ loading: true, success: false, error: null });

    try {
      await simulateSMSSend();
      const newMessage = {
        id: Date.now(),
        recipient:
          smsData.sendMode === "sendIndividual"
            ? smsData.recipientsList.join(", ")
            : smsData.sendMode === "sendAll"
            ? "All Users"
            : smsData.group,
        message: smsData.message,
        group:
          smsData.sendMode === "sendIndividual" ? "Individual" : smsData.group,
        timestamp: new Date().toLocaleString(),
        status: "Sent",
      };

      setSentMessages((prev) => [newMessage, ...prev]);
      setSmsData({
        recipients: "",
        message: "",
        recipientsList: [],
        group: "",
        sendMode: "sendAll",
      });
      setMessageStatus({
        loading: false,
        success: true,
        error: null,
      });

      setTimeout(() => {
        setMessageStatus((prev) => ({ ...prev, success: false }));
      }, 3000);
    } catch (error) {
      setMessageStatus({
        loading: false,
        success: false,
        error: error.message || "Failed to send SMS",
      });
    }
  };
  

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 flex items-center">
          <MessageCircle className="mr-3 text-purple-500" size={32} />
          SMS Communication Module
        </h1>

        <form
          onSubmit={handleSendSMS}
          className="bg-white p-6 shadow-md rounded-lg mb-6"
        >
          {/* Select Group Dropdown */}
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">
              Select Group
            </label>
            <select
              name="sendMode"
              value={smsData.sendMode}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500"
            >
              <option value="sendAll">Send to All</option>
              <option value="sendIndividual">Send to Individual</option>
              <option value="sendGroup">Send to Group</option>
            </select>
          </div>

          {/* Individual SMS Recipient Input */}
          {smsData.sendMode === "sendIndividual" && (
            <div className="mb-4">
              <div className="flex flex-wrap gap-2 mb-4">
                {smsData.recipientsList?.map((recipient, index) => (
                  <div
                    key={index}
                    className="bg-blue-100 text-blue-800 p-2 rounded-full flex items-center"
                  >
                    {recipient}
                    <button
                      type="button"
                      onClick={() => handleRemoveRecipient(index)}
                      className="ml-2 text-blue-600 hover:text-blue-800"
                    >
                      <XCircle size={16} />
                    </button>
                  </div>
                ))}
              </div>

              <div className="flex items-center">
                <input
                  type="tel"
                  name="recipients"
                  value={smsData.recipients}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500"
                  placeholder="Add phone number"
                  onKeyDown={(e) => {
                    // Prevent form submission on Enter
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission if inside a form
                      handleAddRecipient(e);
                    }
                  }}
                />
                <button
                  type="button"
                  onClick={handleAddRecipient}
                  className="ml-2 bg-purple-500 text-white p-3 rounded-full"
                >
                  <Send size={20} />
                </button>
              </div>
            </div>
          )}

          {/* Group Selection for 'Send to Group' */}
          {smsData.sendMode === "sendGroup" && (
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Select Group
              </label>
              <select
                name="group"
                value={smsData.group}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500"
              >
                <option value="">Choose a group</option>
                <option value="Beneficiaries">Beneficiaries</option>
                <option value="Clients">Clients</option>
                <option value="Payment Due">Payment Due</option>
              </select>
            </div>
          )}

          {/* Message Input */}
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">
              Message
            </label>
            <textarea
              name="message"
              value={smsData.message}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500"
              rows="4"
              placeholder="Write your message here"
              required
              maxLength="160"
            ></textarea>
            <p className="text-sm text-gray-500 mt-1">
              {smsData.message.length}/160 characters
            </p>
          </div>

          <button
            type="submit"
            disabled={messageStatus.loading}
            className={`w-full flex items-center justify-center p-3 rounded-lg text-white font-bold transition-colors duration-300 ${
              messageStatus.loading
                ? "bg-purple-300 cursor-not-allowed"
                : "bg-purple-500 hover:bg-purple-600"
            }`}
          >
            {messageStatus.loading ? (
              <>Sending...</>
            ) : (
              <>
                <Send className="mr-2" size={20} /> Send SMS
              </>
            )}
          </button>

          {messageStatus.success && (
            <div className="mt-4 bg-green-50 p-3 rounded flex items-center text-green-700">
              <CheckCircle2 className="mr-2" size={20} />
              SMS sent successfully!
            </div>
          )}

          {messageStatus.error && (
            <div className="mt-4 bg-red-50 p-3 rounded text-red-700">
              {messageStatus.error}
            </div>
          )}
        </form>

        <div className="bg-white p-6 shadow-md rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold flex items-center">
              <Inbox className="mr-2 text-purple-500" size={24} />
              Sent Messages
            </h2>
          </div>

          {sentMessages.length === 0 ? (
            <p className="text-center text-gray-500">No messages sent yet.</p>
          ) : (
            <div className="space-y-4">
              {sentMessages.map((msg) => (
                <div
                  key={msg.id}
                  className="bg-gray-50 p-4 rounded-lg border border-gray-200"
                >
                  <div className="flex justify-between">
                    <div>
                      <p className="font-semibold text-gray-800">
                        Recipient: {msg.recipient} - Group: {msg.group}
                      </p>
                      <p className="text-gray-600 mt-1">{msg.message}</p>
                      <p className="text-sm text-gray-500 mt-2">
                        {msg.timestamp}
                      </p>
                    </div>
                    <span className="text-green-500 font-medium">
                      {msg.status}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SMSPage;
