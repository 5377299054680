import React, { useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import { List, Filter, Plus, Crop, MapPin, ChevronDown } from "lucide-react";

const CategorizationPage = () => {
  const [categories] = useState([
    { name: "Canal Irrigation", value: 400 },
    { name: "Sprinkler Irrigation", value: 300 },
    { name: "Drip Irrigation", value: 200 },
  ]);

  const [filters, setFilters] = useState({
    cropType: "",
    landSizeMin: "",
    landSizeMax: "",
  });

  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const applyFilters = () => {
    // Implement filter logic
    console.log("Applying filters:", filters);
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Categorization Visualization */}
          <div className="md:col-span-2 bg-white shadow-lg rounded-xl p-6 border border-gray-100">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-800 flex items-center">
                <Crop className="mr-3 text-blue-500" size={28} />
                Beneficiary Categorization
              </h2>
              <button className="text-blue-500 hover:bg-blue-50 p-2 rounded-full">
                <List size={24} />
              </button>
            </div>

            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={categories}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {categories.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="center"
                />
              </PieChart>
            </ResponsiveContainer>
          </div>

          {/* Categorization Filters */}
          <div className="bg-white shadow-lg rounded-xl p-6 border border-gray-100">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-800 flex items-center">
                <Filter className="mr-3 text-green-500" size={28} />
                Categorization Filters
              </h2>
              <button
                onClick={() => setIsAdvancedFiltersOpen(!isAdvancedFiltersOpen)}
                className="text-gray-500 hover:bg-gray-50 p-2 rounded-full"
              >
                <ChevronDown
                  size={24}
                  className={`transition-transform ${
                    isAdvancedFiltersOpen ? "rotate-180" : ""
                  }`}
                />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Crop Type
                </label>
                <select
                  name="cropType"
                  value={filters.cropType}
                  onChange={handleFilterChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">All Crops</option>
                  <option value="wheat">Wheat</option>
                  <option value="rice">Rice</option>
                  <option value="maize">Maize</option>
                </select>
              </div>

              {isAdvancedFiltersOpen && (
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Min Land Size
                    </label>
                    <input
                      type="number"
                      name="landSizeMin"
                      value={filters.landSizeMin}
                      onChange={handleFilterChange}
                      placeholder="Min"
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Max Land Size
                    </label>
                    <input
                      type="number"
                      name="landSizeMax"
                      value={filters.landSizeMax}
                      onChange={handleFilterChange}
                      placeholder="Max"
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                </div>
              )}

              <button
                onClick={applyFilters}
                className="w-full bg-green-500 text-white p-3 rounded-lg hover:bg-green-600 transition-colors flex items-center justify-center"
              >
                <Plus className="mr-2" size={20} />
                Apply Categorization
              </button>
            </div>

            {/* Quick Stats */}
            <div className="bg-gray-50 rounded-lg p-4 mt-6">
              <h4 className="text-sm font-semibold text-gray-700 mb-3 flex items-center">
                <MapPin className="mr-2 text-blue-500" size={16} />
                Quick Statistics
              </h4>
              <div className="grid grid-cols-3 gap-2 text-center">
                <div>
                  <p className="text-sm text-gray-600">Total</p>
                  <p className="font-bold text-blue-600">900</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Active</p>
                  <p className="font-bold text-green-600">750</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Inactive</p>
                  <p className="font-bold text-red-600">150</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategorizationPage;
