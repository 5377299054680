import React, { useState, useEffect } from "react";
import { UserPlus } from "lucide-react";
import axios from "axios";
import BeneficiaryList from "../components/BeneficiaryList";
import { useNavigate } from "react-router-dom"; // To redirect to the BeneficiaryForm page

// Profile Management Component
const ProfileManagement = () => {
  const navigate = useNavigate();  // To navigate to the BeneficiaryForm page
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCropType, setFilterCropType] = useState("");

  // Fetch beneficiaries from the backend
  useEffect(() => {
    const fetchBeneficiaries = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/api/beneficiaries");
        setBeneficiaries(response.data);
      } catch (err) {
        setError("Failed to fetch beneficiaries");
      } finally {
        setLoading(false);
      }
    };

    fetchBeneficiaries();
  }, []);

  const handleDeleteBeneficiary = async (id) => {
    try {
      await axios.delete(`/api/beneficiaries/${id}`);
      setBeneficiaries((prev) => prev.filter((b) => b.id !== id));
    } catch (err) {
      setError("Failed to delete beneficiary");
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEditBeneficiary = (beneficiary) => {
    navigate(`/beneficiaryform/${beneficiary.id}`);  // Redirect to the edit page
  };

  const handleAddBeneficiary = () => {
    navigate("/beneficiaryform");  // Redirect to add new beneficiary form
  };

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-12">
          Beneficiary Management
        </h1>

        <div className="flex justify-between mb-6">
          <button
            onClick={handleAddBeneficiary}
            className="flex items-center text-white bg-blue-600 px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
          >
            <UserPlus className="mr-2" />
            Add New Beneficiary
          </button>

          {/* Search Input */}
          <input
            type="text"
            placeholder="Search by Name or Phone"
            value={searchTerm}
            onChange={handleSearchChange}
            className="border rounded-md p-2"
          />
        </div>

        {loading && <p>Loading beneficiaries...</p>}
        {error && <p className="text-red-500">{error}</p>}

        <BeneficiaryList
          beneficiaries={beneficiaries.filter((beneficiary) => {
            const searchLower = searchTerm.toLowerCase();
            return (
              beneficiary.name.toLowerCase().includes(searchLower) ||
              beneficiary.phone.includes(searchLower)
            );
          })}
          onEdit={handleEditBeneficiary}
          onDelete={handleDeleteBeneficiary}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filterCropType={filterCropType}
          setFilterCropType={setFilterCropType}
        />
      </div>
    </div>
  );
};

export default ProfileManagement;
