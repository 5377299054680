import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";


const BeneficiaryForm = () => {
  const [formData, setFormData] = useState({
    // Basic Information
    number: "",
    nameEnglish: "",
    nameNepali: "",
    dateOfBirth: "",
    citizenshipNumber: "",
    citizenshipIssueDate: "",
    citizenshipDistrict: "",

    // Education
    literacyStatus: "",
    literacyLevel: "",

    // Address
    tempDistrict: "",
    tempMunicipality: "",
    tempWard: "",

    // Work Details
    workLocation: "",
    agriculturalSector: [],

    // Land Holdings
    landSize: {
      bigha: "",
      katha: "",
      dhar: "",
    },
    irrigationSource: "",
    irrigatedArea: "",

    // Family Details
    totalFamilyMembers: "",
    familyMembersInAgriculture: "",
    familyMemberRoles: [
      {
        name: "",
        role: "",
        area: "", // Farming area
        sameAddress: true, // Boolean to determine if additional address is needed
        address: {
          district: "",
          municipality: "",
          ward: "",
        },
      },
    ],

    // Income
    annualIncome: "",
    timeSpentInAgriculture: "",

    // Livestock Details
    livestockOwned: [],
    livestockProduction: {
      goat: { quantity: "", production: "" },
      buffalo: { quantity: "", production: "" },
      poultry: { quantity: "", production: "" },
      fish: { quantity: "", production: "" },
    },

    // Crop Production
    cropsGrown: [],
    cropQuantities: {
      cereals: { quantity: "" },
      fruits: { quantity: "" },
      vegetables: { quantity: "" },
      mushrooms: { quantity: "" },
    },
    grassCultivation: {
      species: "",
      area: "",
      quantity: "",
    },

    // Agricultural Equipment
    machinery: [],
    machineryOther: "",

    // Financial Information
    agriculturalLoans: {
      taken: "",
      source: "",
      amount: "",
    },
    agriculturalInsurance: {
      enrolled: "",
      insuredItems: [],
    },

    // Other Questions
    landAccessibility: "",
    farmingInOtherMunicipalities: {
      status: "",
      details: "",
    },
    landLentToOthers: {
      status: "",
      details: "",
    },
  });

  const [expandedSections, setExpandedSections] = useState({
    basicInfo: true,
    education: true,
    address: true,
    workDetails: true,
    landHoldings: true,
    familyDetails: true,
    income: true,
    livestock: true,
    cropProduction: true,
    agriculturalEquipment: true,
    financialInfo: true,
    otherQuestions: true,
  });

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleInputChange = (e, section, subKey = null) => {
    const { name, value, type, checked } = e.target;

    setFormData((prev) => {
      if (subKey) {
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [subKey]:
              type === "checkbox"
                ? checked
                  ? [...(prev[section][subKey] || []), value]
                  : (prev[section][subKey] || []).filter(
                      (item) => item !== value
                    )
                : value,
          },
        };
      }

      return {
        ...prev,
        [name]:
          type === "checkbox"
            ? checked
              ? [...(prev[name] || []), value]
              : (prev[name] || []).filter((item) => item !== value)
            : value,
      };
    });
  };

  const handleSelectChange = (e, section, name) => {
    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [name]: e.target.value,
      },
    }));
  };
  const handleNestedInputChange = (section, key, subKey, value) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [section]: {
          ...prevState[section],
          [key]: {
            ...prevState[section][key],
            [subKey]: value,
          },
        },
      };
    });
  };

  const handleNestedArrayInputChange = (section, index, field, value) => {
    setFormData((prevState) => {
      const updatedArray = [...prevState[section]]; // Clone the array
      const [nestedField, subField] = field.split("."); // Check if it's a deeply nested field

      if (subField) {
        // Handle deeply nested fields (e.g., address.district)
        updatedArray[index] = {
          ...updatedArray[index],
          [nestedField]: {
            ...updatedArray[index]?.[nestedField],
            [subField]: value,
          },
        };
      } else {
        // Handle flat fields (e.g., name, role, area)
        updatedArray[index] = {
          ...updatedArray[index],
          [field]: value,
        };
      }

      return {
        ...prevState,
        [section]: updatedArray,
      };
    });
  };
  const handleCheckboxChange = (e, section) => {
    const { value, checked } = e.target;
    setFormData((prevState) => {
      let updatedItems = [...prevState[section]];

      if (checked) {
        updatedItems.push(value);
      } else {
        updatedItems = updatedItems.filter((item) => item !== value);
      }

      return { ...prevState, [section]: updatedItems };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Log the form data as a JSON object to the console
    console.log("Form Data:", JSON.stringify(formData, null, 2));
  
  };
  
  const SectionHeader = ({ title, section }) => (
    <div
      className="flex justify-between items-center bg-blue-100 p-3 cursor-pointer"
      onClick={() => toggleSection(section)}
    >
      <h2 className="text-lg font-semibold">{title}</h2>
      {expandedSections[section] ? <ChevronUp /> : <ChevronDown />}
    </div>
  );

  const renderBasicInfo = () => (
    <div className={`p-4 ${expandedSections.basicInfo ? "block" : "hidden"}`}>
      <div className="grid md:grid-cols-3 gap-4">
        {/* English Name */}
        <div>
          <label className="block text-sm font-medium">First Name (English)</label>
          <input
            type="text"
            name="englishFirstName"
            value={formData.englishFirstName}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Middle Name (English)</label>
          <input
            type="text"
            name="englishMiddleName"
            value={formData.englishMiddleName}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Last Name (English)</label>
          <input
            type="text"
            name="englishLastName"
            value={formData.englishLastName}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
  
        {/* Nepali Name */}
        <div>
          <label className="block text-sm font-medium">First Name (Nepali)</label>
          <input
            type="text"
            name="nepaliFirstName"
            value={formData.nepaliFirstName}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Middle Name (Nepali)</label>
          <input
            type="text"
            name="nepaliMiddleName"
            value={formData.nepaliMiddleName}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Last Name (Nepali)</label>
          <input
            type="text"
            name="nepaliLastName"
            value={formData.nepaliLastName}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
  
        {/* Date of Birth */}
        <div>
          <label className="block text-sm font-medium">Date of Birth (BS)</label>
          <input
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
  
        {/* Phone Number */}
        <div>
          <label className="block text-sm font-medium">Phone Number</label>
          <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
  
        {/* Citizenship Details */}
        <div>
          <label className="block text-sm font-medium">Citizenship Number</label>
          <input
            type="text"
            name="citizenshipNumber"
            value={formData.citizenshipNumber}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Issuance Date</label>
          <input
            type="date"
            name="issuanceDate"
            value={formData.issuanceDate}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Issued By</label>
          <input
            type="text"
            name="issuedBy"
            value={formData.issuedBy}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
      </div>
      {/* Rest of basic info fields... */}
    </div>
  );

  const renderEducation = () => (
    <div className={`p-4 ${expandedSections.education ? "block" : "hidden"}`}>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium">Literacy Status</label>
          <div className="flex space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="literacyStatus"
                value="Literate"
                checked={formData.literacyStatus === "Literate"}
                onChange={handleInputChange}
                className="form-radio"
              />
              <span className="ml-2">Literate</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="literacyStatus"
                value="Illiterate"
                checked={formData.literacyStatus === "Illiterate"}
                onChange={handleInputChange}
                className="form-radio"
              />
              <span className="ml-2">Illiterate</span>
            </label>
          </div>
        </div>
        {formData.literacyStatus === "Literate" && (
          <div>
            <label className="block text-sm font-medium">Literacy Level</label>
            <select
              name="literacyLevel"
              value={formData.literacyLevel}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            >
              <option value="">Select Level</option>
              <option value="Primary">Primary</option>
              <option value="Secondary">Secondary</option>
              <option value="Higher">Higher Education</option>
            </select>
          </div>
        )}
      </div>
    </div>
  );
  const renderAddress = () => (
    <div className={`p-4 ${expandedSections.address ? "block" : "hidden"}`}>
      <div className="grid md:grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium">
            Temporary District
          </label>
          <input
            type="text"
            name="tempDistrict"
            value={formData.tempDistrict}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">
            Temporary Municipality
          </label>
          <input
            type="text"
            name="tempMunicipality"
            value={formData.tempMunicipality}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Temporary Ward</label>
          <input
            type="text"
            name="tempWard"
            value={formData.tempWard}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
      </div>
    </div>
  );

  const renderWorkDetails = () => (
    <div className={`p-4 ${expandedSections.workDetails ? "block" : "hidden"}`}>
      <div className="grid md:grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium">Work Location</label>
          <input
            type="text"
            name="workLocation"
            value={formData.workLocation}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">
            Agricultural Sector
          </label>
          <select
            name="agriculturalSector"
            value={formData.agriculturalSector}
            onChange={handleSelectChange} // Assume a custom select handler for array fields
            multiple
            className="mt-1 block w-full border rounded-md p-2"
          >
            <option value="Crops">Crops</option>
            <option value="Livestock">Livestock</option>
            <option value="Poultry">Poultry</option>
            <option value="Horticulture">Horticulture</option>
          </select>
        </div>
      </div>
    </div>
  );

  const renderLandHoldings = () => (
    <div
      className={`p-4 ${expandedSections.landHoldings ? "block" : "hidden"}`}
    >
      <div className="grid md:grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium">Land Size (Bigha)</label>
          <input
            type="text"
            name="landSize.bigha"
            value={formData.landSize.bigha}
            onChange={(e) =>
              handleNestedInputChange("landSize", "bigha", e.target.value)
            }
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Land Size (Katha)</label>
          <input
            type="text"
            name="landSize.katha"
            value={formData.landSize.katha}
            onChange={(e) =>
              handleNestedInputChange("landSize", "katha", e.target.value)
            }
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Land Size (Dhar)</label>
          <input
            type="text"
            name="landSize.dhar"
            value={formData.landSize.dhar}
            onChange={(e) =>
              handleNestedInputChange("landSize", "dhar", e.target.value)
            }
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-4">
        <div>
          <label className="block text-sm font-medium">Irrigation Source</label>
          <input
            type="text"
            name="irrigationSource"
            value={formData.irrigationSource}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Irrigated Area</label>
          <input
            type="text"
            name="irrigatedArea"
            value={formData.irrigatedArea}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
      </div>
    </div>
  );

  const renderFamilyDetails = () => (
    <div
      className={`p-4 ${expandedSections.familyDetails ? "block" : "hidden"}`}
    >
      <div className="grid md:grid-cols-2 gap-4">
        {/* Total Family Members */}
        <div>
          <label className="block text-sm font-medium">
            Total Family Members
          </label>
          <input
            type="number"
            name="totalFamilyMembers"
            value={formData.totalFamilyMembers}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>

        {/* Family Members in Agriculture */}
        <div>
          <label className="block text-sm font-medium">
            Family Members in Agriculture
          </label>
          <input
            type="number"
            name="familyMembersInAgriculture"
            value={formData.familyMembersInAgriculture}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
      </div>

      {/* Additional Details (Conditional Rendering) */}
      {formData.familyMembersInAgriculture > 1 && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">
            Details of Family Members in Agriculture
          </h3>
          {Array.from({ length: formData.familyMembersInAgriculture }).map(
            (_, index) => (
              <div key={index} className="border p-4 mb-4 rounded-lg">
                <h4 className="font-semibold mb-2">Member {index + 1}</h4>
                <div className="grid md:grid-cols-3 gap-4">
                  {/* Name */}
                  <div>
                    <label className="block text-sm font-medium">Name</label>
                    <input
                      type="text"
                      name={`familyMemberRoles[${index}].name`}
                      value={formData.familyMemberRoles[index]?.name || ""}
                      onChange={(e) =>
                        handleNestedArrayInputChange(
                          "familyMemberRoles",
                          index,
                          "name",
                          e.target.value
                        )
                      }
                      className="mt-1 block w-full border rounded-md p-2"
                    />
                  </div>

                  {/* Role in Agriculture */}
                  <div>
                    <label className="block text-sm font-medium">
                      Role in Agriculture
                    </label>
                    <input
                      type="text"
                      name={`familyMemberRoles[${index}].role`}
                      value={formData.familyMemberRoles[index]?.role || ""}
                      onChange={(e) =>
                        handleNestedArrayInputChange(
                          "familyMemberRoles",
                          index,
                          "role",
                          e.target.value
                        )
                      }
                      className="mt-1 block w-full border rounded-md p-2"
                    />
                  </div>

                  {/* Area (Optional) */}
                  <div>
                    <label className="block text-sm font-medium">Area</label>
                    <input
                      type="text"
                      name={`familyMemberRoles[${index}].area`}
                      value={formData.familyMemberRoles[index]?.area || ""}
                      onChange={(e) =>
                        handleNestedArrayInputChange(
                          "familyMemberRoles",
                          index,
                          "area",
                          e.target.value
                        )
                      }
                      className="mt-1 block w-full border rounded-md p-2"
                    />
                  </div>
                </div>

                {/* Address (Conditional Based on "same address" option) */}
                <div className="mt-4">
                  <label className="block text-sm font-medium">
                    Same Address?
                  </label>
                  <div className="flex items-center space-x-4 mt-1">
                    <label>
                      <input
                        type="radio"
                        name={`familyMemberRoles[${index}].sameAddress`}
                        value="yes"
                        checked={
                          formData.familyMemberRoles[index]?.sameAddress ===
                          "yes"
                        }
                        onChange={(e) =>
                          handleNestedArrayInputChange(
                            "familyMemberRoles",
                            index,
                            "sameAddress",
                            e.target.value
                          )
                        }
                      />{" "}
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        name={`familyMemberRoles[${index}].sameAddress`}
                        value="no"
                        checked={
                          formData.familyMemberRoles[index]?.sameAddress ===
                          "no"
                        }
                        onChange={(e) =>
                          handleNestedArrayInputChange(
                            "familyMemberRoles",
                            index,
                            "sameAddress",
                            e.target.value
                          )
                        }
                      />{" "}
                      No
                    </label>
                  </div>
                  {formData.familyMemberRoles[index]?.sameAddress === "no" && (
                    <div className="mt-4">
                      <h5 className="font-semibold mb-2">Member Address</h5>
                      <div className="grid md:grid-cols-3 gap-4">
                        {/* Address Fields */}
                        <div>
                          <label className="block text-sm font-medium">
                            District
                          </label>
                          <input
                            type="text"
                            name={`familyMemberRoles[${index}].address.district`}
                            value={
                              formData.familyMemberRoles[index]?.address
                                ?.district || ""
                            }
                            onChange={(e) =>
                              handleNestedArrayInputChange(
                                "familyMemberRoles",
                                index,
                                "address.district",
                                e.target.value
                              )
                            }
                            className="mt-1 block w-full border rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium">
                            Municipality
                          </label>
                          <input
                            type="text"
                            name={`familyMemberRoles[${index}].address.municipality`}
                            value={
                              formData.familyMemberRoles[index]?.address
                                ?.municipality || ""
                            }
                            onChange={(e) =>
                              handleNestedArrayInputChange(
                                "familyMemberRoles",
                                index,
                                "address.municipality",
                                e.target.value
                              )
                            }
                            className="mt-1 block w-full border rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium">
                            Ward
                          </label>
                          <input
                            type="text"
                            name={`familyMemberRoles[${index}].address.ward`}
                            value={
                              formData.familyMemberRoles[index]?.address
                                ?.ward || ""
                            }
                            onChange={(e) =>
                              handleNestedArrayInputChange(
                                "familyMemberRoles",
                                index,
                                "address.ward",
                                e.target.value
                              )
                            }
                            className="mt-1 block w-full border rounded-md p-2"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );

  const renderIncomeAndResources = () => (
    <div className={`p-4 ${expandedSections.income ? "block" : "hidden"}`}>
      <div className="mb-4">
        <label className="block text-sm font-medium">Annual Income (NPR)</label>
        <input
          type="number"
          name="annualIncome"
          value={formData.annualIncome}
          onChange={handleInputChange}
          className="mt-1 block w-full border rounded-md p-2"
          placeholder="Enter annual income in NPR"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">
          Time Spent in Agriculture (hours/week)
        </label>
        <input
          type="number"
          name="timeSpentInAgriculture"
          value={formData.timeSpentInAgriculture}
          onChange={handleInputChange}
          className="mt-1 block w-full border rounded-md p-2"
          placeholder="Enter hours per week"
        />
      </div>
    </div>
  );

  const renderLivestockDetails = () => (
    <div className={`p-4 ${expandedSections.livestock ? "block" : "hidden"}`}>
      <div className="mb-4">
        <label className="block text-sm font-medium">
          Types of Livestock Owned
        </label>
        <div className="flex items-center space-x-4">
          <label>
            <input
              type="checkbox"
              name="livestockOwned"
              value="goat"
              checked={formData.livestockOwned.includes("goat")}
              onChange={(e) => handleCheckboxChange(e, "livestockOwned")}
              className="mr-2"
            />
            Goat
          </label>
          <label>
            <input
              type="checkbox"
              name="livestockOwned"
              value="buffalo"
              checked={formData.livestockOwned.includes("buffalo")}
              onChange={(e) => handleCheckboxChange(e, "livestockOwned")}
              className="mr-2"
            />
            Buffalo
          </label>
          <label>
            <input
              type="checkbox"
              name="livestockOwned"
              value="poultry"
              checked={formData.livestockOwned.includes("poultry")}
              onChange={(e) => handleCheckboxChange(e, "livestockOwned")}
              className="mr-2"
            />
            Poultry
          </label>
          <label>
            <input
              type="checkbox"
              name="livestockOwned"
              value="fish"
              checked={formData.livestockOwned.includes("fish")}
              onChange={(e) => handleCheckboxChange(e, "livestockOwned")}
              className="mr-2"
            />
            Fish
          </label>
          {/* Add more options as needed */}
        </div>
      </div>

      {formData.livestockOwned.length > 0 && (
        <div>
          {formData.livestockOwned.map((livestock) => (
            <div key={livestock} className="mb-4">
              <h3 className="text-sm font-medium">{livestock} Details</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium">
                    Quantity of {livestock}
                  </label>
                  <input
                    type="number"
                    name={`${livestock}_quantity`}
                    value={
                      formData.livestockProduction[livestock]?.quantity || ""
                    }
                    onChange={(e) =>
                      handleNestedInputChange(
                        "livestockProduction",
                        livestock,
                        "quantity",
                        e.target.value
                      )
                    }
                    className="mt-1 block w-full border rounded-md p-2"
                    placeholder={`Enter number of ${livestock}`}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium">
                    Production of {livestock}
                  </label>
                  <input
                    type="number"
                    name={`${livestock}_production`}
                    value={
                      formData.livestockProduction[livestock]?.production || ""
                    }
                    onChange={(e) =>
                      handleNestedInputChange(
                        "livestockProduction",
                        livestock,
                        "production",
                        e.target.value
                      )
                    }
                    className="mt-1 block w-full border rounded-md p-2"
                    placeholder={`Enter production of ${livestock}`}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const renderCropProduction = () => (
    <div
      className={`p-4 ${expandedSections.cropProduction ? "block" : "hidden"}`}
    >
      {/* Types of Crops Grown */}
      <div className="mb-4">
        <label className="block text-sm font-medium">
          Types of Crops Grown
        </label>
        <div className="flex items-center space-x-4">
          <label>
            <input
              type="checkbox"
              name="cropsGrown"
              value="cereals"
              checked={formData.cropsGrown.includes("cereals")}
              onChange={(e) => handleCheckboxChange(e, "cropsGrown")}
              className="mr-2"
            />
            Cereals
          </label>
          <label>
            <input
              type="checkbox"
              name="cropsGrown"
              value="fruits"
              checked={formData.cropsGrown.includes("fruits")}
              onChange={(e) => handleCheckboxChange(e, "cropsGrown")}
              className="mr-2"
            />
            Fruits
          </label>
          <label>
            <input
              type="checkbox"
              name="cropsGrown"
              value="vegetables"
              checked={formData.cropsGrown.includes("vegetables")}
              onChange={(e) => handleCheckboxChange(e, "cropsGrown")}
              className="mr-2"
            />
            Vegetables
          </label>
          <label>
            <input
              type="checkbox"
              name="cropsGrown"
              value="mushrooms"
              checked={formData.cropsGrown.includes("mushrooms")}
              onChange={(e) => handleCheckboxChange(e, "cropsGrown")}
              className="mr-2"
            />
            Mushrooms
          </label>
          {/* Add more crop options as needed */}
        </div>
      </div>

      {/* Quantity Produced */}
      {formData.cropsGrown.length > 0 && (
        <div className="mb-4">
          {formData.cropsGrown.map((crop) => (
            <div key={crop} className="mb-4">
              <h3 className="text-sm font-medium">{crop} Quantity</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium">
                    Quantity of {crop}
                  </label>
                  <input
                    type="number"
                    name={`${crop}_quantity`}
                    value={formData.cropQuantities[crop]?.quantity || ""}
                    onChange={(e) =>
                      handleNestedInputChange(
                        "cropQuantities",
                        crop,
                        "quantity",
                        e.target.value
                      )
                    }
                    className="mt-1 block w-full border rounded-md p-2"
                    placeholder={`Enter quantity of ${crop}`}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Grass and Fodder Cultivation */}
      {/* {formData.cropsGrown.includes("grass") ||
      formData.cropsGrown.includes("fodder") ? (
        <div className="mb-4">
          <h3 className="text-sm font-medium">Grass and Fodder Cultivation</h3>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium">Species</label>
              <input
                type="text"
                name="grassSpecies"
                value={formData.grassCultivation.species}
                onChange={(e) =>
                  handleNestedInputChange(
                    "grassCultivation",
                    "species",
                    e.target.value
                  )
                }
                className="mt-1 block w-full border rounded-md p-2"
                placeholder="Enter species"
              />
            </div>

            <div>
              <label className="block text-sm font-medium">
                Area Cultivated
              </label>
              <input
                type="text"
                name="grassArea"
                value={formData.grassCultivation.area}
                onChange={(e) =>
                  handleNestedInputChange(
                    "grassCultivation",
                    "area",
                    e.target.value
                  )
                }
                className="mt-1 block w-full border rounded-md p-2"
                placeholder="Enter area in square meters"
              />
            </div>

            <div>
              <label className="block text-sm font-medium">Quantity</label>
              <input
                type="number"
                name="grassQuantity"
                value={formData.grassCultivation.quantity}
                onChange={(e) =>
                  handleNestedInputChange(
                    "grassCultivation",
                    "quantity",
                    e.target.value
                  )
                }
                className="mt-1 block w-full border rounded-md p-2"
                placeholder="Enter quantity of grass"
              />
            </div>
          </div>
        </div>
      ) : null} */}
    </div>
  );

  const renderAgriculturalEquipment = () => (
    <div
      className={`p-4 ${
        expandedSections.agriculturalEquipment ? "block" : "hidden"
      }`}
    >
      <div className="mb-6">
        <h3 className="text-sm font-medium mb-2">Machinery:</h3>
        <div className="flex flex-col space-y-3">
          {["owned", "rented"].map((option) => (
            <label key={option} className="flex items-center">
              <input
                type="checkbox"
                value={option}
                checked={formData.machinery.includes(option)}
                onChange={(e) => handleCheckboxChange(e, "machinery")}
                className="mr-2"
              />
              {option.charAt(0).toUpperCase() + option.slice(1)} Machinery
            </label>
          ))}
        </div>
      </div>
    </div>
  );

  const renderFinancialInfo = () => (
    <div
      className={`p-4 ${expandedSections.financialInfo ? "block" : "hidden"}`}
    >
      <div className="mb-6">
        <h3 className="text-sm font-medium mb-2">Agricultural Loans:</h3>
        <div className="flex flex-col space-y-3">
          <div>
            <label className="block text-sm font-medium">Taken?</label>
            <div className="flex space-x-4">
              <label>
                <input
                  type="radio"
                  name="agriculturalLoansTaken"
                  value="yes"
                  checked={formData.agriculturalLoans.taken === "yes"}
                  onChange={(e) =>
                    handleInputChange(e, "agriculturalLoans", "taken")
                  }
                  className="mr-2"
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="agriculturalLoansTaken"
                  value="no"
                  checked={formData.agriculturalLoans.taken === "no"}
                  onChange={(e) =>
                    handleInputChange(e, "agriculturalLoans", "taken")
                  }
                  className="mr-2"
                />{" "}
                No
              </label>
            </div>
          </div>

          {formData.agriculturalLoans.taken === "yes" && (
            <div>
              <div>
                <label className="block text-sm font-medium">Source</label>
                <select
                  name="agriculturalLoansSource"
                  value={formData.agriculturalLoans.source}
                  onChange={(e) =>
                    handleInputChange(e, "agriculturalLoans", "source")
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                >
                  <option value="bank">Bank</option>
                  <option value="cooperative">Cooperative</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div className="mt-4">
                <label className="block text-sm font-medium">Amount</label>
                <input
                  type="number"
                  name="agriculturalLoansAmount"
                  value={formData.agriculturalLoans.amount}
                  onChange={(e) =>
                    handleInputChange(e, "agriculturalLoans", "amount")
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                  placeholder="Enter loan amount"
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mb-6">
        <h3 className="text-sm font-medium mb-2">Agricultural Insurance:</h3>
        <div className="flex flex-col space-y-3">
          <div>
            <label className="block text-sm font-medium">Enrolled?</label>
            <div className="flex space-x-4">
              <label>
                <input
                  type="radio"
                  name="agriculturalInsuranceEnrolled"
                  value="yes"
                  checked={formData.agriculturalInsurance.enrolled === "yes"}
                  onChange={(e) =>
                    handleInputChange(e, "agriculturalInsurance", "enrolled")
                  }
                  className="mr-2"
                />{" "}
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="agriculturalInsuranceEnrolled"
                  value="no"
                  checked={formData.agriculturalInsurance.enrolled === "no"}
                  onChange={(e) =>
                    handleInputChange(e, "agriculturalInsurance", "enrolled")
                  }
                  className="mr-2"
                />{" "}
                No
              </label>
            </div>
          </div>

          {formData.agriculturalInsurance.enrolled === "yes" && (
            <div>
              <div>
                <label className="block text-sm font-medium">
                  Insured Items
                </label>
                <textarea
                  name="agriculturalInsuranceInsuredItems"
                  value={formData.agriculturalInsurance.insuredItems}
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "agriculturalInsurance",
                      "insuredItems"
                    )
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                  placeholder="Enter details of insured crops or livestock"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  const renderLandAccessibility = () => (
    <div className="p-4">
      <h3 className="font-semibold text-lg">Accessibility of Land</h3>
      <label className="block">
        <input
          type="radio"
          name="landAccessibility"
          value="yes"
          checked={formData.landAccessibility === "yes"}
          onChange={(e) => handleInputChange(e)}
          className="mr-2"
        />
        Yes
      </label>
      <label className="block">
        <input
          type="radio"
          name="landAccessibility"
          value="no"
          checked={formData.landAccessibility === "no"}
          onChange={(e) => handleInputChange(e)}
          className="mr-2"
        />
        No
      </label>
    </div>
  );
  const renderFarmingInOtherMunicipalities = () => (
    <div className="p-4">
      <h3 className="font-semibold text-lg">Farming in Other Municipalities</h3>
      <label className="block">
        <input
          type="radio"
          name="farmingInOtherMunicipalities.status"
          value="yes"
          checked={formData.farmingInOtherMunicipalities.status === "yes"}
          onChange={(e) =>
            handleInputChange(e, "farmingInOtherMunicipalities", "status")
          }
          className="mr-2"
        />
        Yes
      </label>
      <label className="block">
        <input
          type="radio"
          name="farmingInOtherMunicipalities.status"
          value="no"
          checked={formData.farmingInOtherMunicipalities.status === "no"}
          onChange={(e) =>
            handleInputChange(e, "farmingInOtherMunicipalities", "status")
          }
          className="mr-2"
        />
        No
      </label>

      {formData.farmingInOtherMunicipalities.status === "yes" && (
        <div>
          <label className="block text-sm font-medium">Details</label>
          <input
            type="text"
            name="details"
            value={formData.farmingInOtherMunicipalities.details}
            onChange={(e) =>
              handleInputChange(e, "farmingInOtherMunicipalities", "details")
            }
            className="mt-1 block w-full border rounded-md p-2"
            placeholder="Enter details of farming in other municipalities"
          />
        </div>
      )}
    </div>
  );

  return (
    <div className="container mx-auto p-6 bg-gray-50">
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg">
        <SectionHeader title="Basic Information" section="basicInfo" />
        {renderBasicInfo()}
        <SectionHeader title="Education" section="education" />
        {renderEducation()}
        <SectionHeader title="Address" section="address" />
        {renderAddress()}
        <SectionHeader title="Work Details" section="workDetails" />
        {renderWorkDetails()}
        <SectionHeader title="Land Holdings" section="landHoldings" />
        {renderLandHoldings()}
        <SectionHeader title="Family Details" section="familyDetails" />
        {renderFamilyDetails()}
        <SectionHeader title="Income and Resources" section="income" />
        {renderIncomeAndResources()}
        <SectionHeader title="Livestock Details" section="livestock" />
        {renderLivestockDetails()}
        <SectionHeader title="Crop Production" section="cropProduction" />
        {renderCropProduction()}
        <SectionHeader
          title="Agricultural Equipment"
          section="agriculturalEquipment"
        />
        {renderAgriculturalEquipment()}
        <SectionHeader title="Financial Information" section="financialInfo" />
        {renderFinancialInfo()}
        <SectionHeader title="Land Accessibility" section="landAccessibility" />
        {renderLandAccessibility()}
        <SectionHeader
          title="Farming in Other Municipalities"
          section="farmingInOtherMunicipalities"
        />
        {renderFarmingInOtherMunicipalities()}

        {/* Add more section headers and render methods for other sections */}

        <div className="p-4">
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition"
          >
            Submit Survey
          </button>
        </div>
      </form>
    </div>
  );
};

export default BeneficiaryForm;
