import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Home as HomeIcon,
  Users as UserGroupIcon,
  List as CategoryIcon,
  MessageCircle as MessageIcon,
} from "lucide-react";

const Sidebar = ({ isMobile, isOpen, onClose }) => {
  const location = useLocation();

  const menuItems = [
    {
      path: "/",
      icon: HomeIcon,
      label: "Dashboard",
    },
    {
      path: "/BeneficiaryForm",
      icon: UserGroupIcon,
      label: "Beneficiary Form",
    },
    {
      path: "/beneficiaries",
      icon: UserGroupIcon,
      label: "Beneficiary Management",
    },
    {
      path: "/categorization",
      icon: CategoryIcon,
      label: "Categorization",
    },
    {
      path: "/sms",
      icon: MessageIcon,
      label: "SMS Service",
    },
  ];

  const sidebarClasses = `
    ${
      isMobile
        ? `fixed z-50 top-0 left-0 w-64 h-full transform transition-transform duration-300 ease-in-out 
        ${isOpen ? "translate-x-0" : "-translate-x-full"}
        md:static md:translate-x-0 md:block`
        : "w-64"
    }
    bg-white shadow-md border-r
  `;

  return (
    <>
      {isMobile && isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40 md:hidden"
          onClick={onClose}
        />
      )}
      <div className={sidebarClasses}>
        <div className="p-4 md:p-6 border-b flex justify-between items-center">
          <h1 className="text-xl md:text-2xl font-bold text-gray-800">
            MIMO System
          </h1>
          {isMobile && (
            <button
              onClick={onClose}
              className="md:hidden text-gray-600 focus:outline-none"
            >
              ✕
            </button>
          )}
        </div>
        <nav className="mt-4">
          {menuItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              onClick={isMobile ? onClose : undefined}
              className={`
                flex items-center p-3 md:p-4 transition-colors duration-200
                ${
                  location.pathname === item.path
                    ? "bg-blue-50 text-blue-600 border-r-4 border-blue-600"
                    : "text-gray-600 hover:bg-gray-50"
                }
              `}
            >
              <item.icon className="mr-3" size={20} />
              {item.label}
            </Link>
          ))}
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
