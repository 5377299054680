import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard";
import BeneficiaryForm from "./pages/BeneficiaryForm";
import ProfileManagement from "./pages/ProfileManagement";
import CategorizationPage from "./pages/CategorizationPage";
import SMSPage from "./pages/SMSPage";
import { Menu, X } from "lucide-react";

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      {/* Mobile Header */}
      <div className="md:hidden flex items-center justify-between p-4 bg-white shadow-md">
        <h1 className="text-xl font-bold text-gray-800">MIMO System</h1>
        <button
          onClick={toggleSidebar}
          className="text-gray-600 focus:outline-none"
        >
          {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {/* Sidebar for Mobile and Desktop */}
      <Sidebar isMobile={true} isOpen={isSidebarOpen} onClose={toggleSidebar} />

      {/* Main Content Area */}
      <main className="flex-1 overflow-y-auto p-4 md:p-6 transition-all duration-300">
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/beneficiaryForm" element={<BeneficiaryForm />} />
          <Route path="/beneficiaries" element={<ProfileManagement />} />
          <Route path="/categorization" element={<CategorizationPage />} />
          <Route path="/sms" element={<SMSPage />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
