import React from "react";
import { Link } from "react-router-dom";
import { Users, ListOrdered, MessageCircle } from "lucide-react";
import EmblemofNepal from "../asset/EmblemofNepal.png"; // Adjust path if necessary
import Flag from "../asset/Flag.gif";

const GovLogo = () => (
  <img
    src={EmblemofNepal}
    alt="Emblem of Nepal"
    className="w-32 md:w-48 h-28 md:h-44 object-container  mx-auto mb-4 md:mb-0 "
  />
);

const FlagOfNepal = () => (
  <div className="flex justify-center items-center my-4">
    <img
      src={Flag}
      alt="Flag of Nepal"
      className="w-16 md:w-28 object-container "
    />
  </div>
);

function DashboardCard({
  title,
  description,
  icon: Icon,
  to,
  bgColor,
  textColor,
}) {
  return (
    <Link
      to={to}
      className="group transform transition-all duration-300 ease-in-out hover:scale-105"
    >
      <div
        className={`
          ${bgColor} 
          relative 
          overflow-hidden 
          rounded-3xl 
          p-5 
          md:p-6 
          shadow-lg 
          hover:shadow-xl 
          transition-all 
          duration-300 
          border-2 
          border-opacity-20 
          hover:border-opacity-50
          h-full 
          flex 
          flex-col
        `}
      >
        <div className="relative z-10 flex flex-col flex-grow">
          <div className="flex items-center justify-between mb-3 md:mb-4">
            <h3
              className={`
                ${textColor} 
                text-lg 
                md:text-xl 
                font-bold 
                tracking-tight 
                group-hover:text-opacity-80 
                transition-all 
                line-clamp-2 
                min-h-[2.5rem]
              `}
            >
              {title}
            </h3>
            <Icon
              className={`
                ${textColor} 
                w-6 
                h-6 
                md:w-8 
                md:h-8 
                opacity-70 
                group-hover:opacity-100 
                transition-opacity
              `}
              strokeWidth={1.5}
            />
          </div>
          <p
            className={`
              text-gray-600 
              text-xs 
              md:text-sm 
              flex-grow 
              line-clamp-3 
              mb-3 
              md:mb-4 
              min-h-[4.5rem]
              group-hover:text-opacity-80 
              transition-opacity
            `}
          >
            {description}
          </p>
          <div
            className={`
              mt-auto 
              h-1 
              w-0 
              group-hover:w-full 
              ${textColor} 
              opacity-0 
              group-hover:opacity-50 
              transition-all 
              duration-300 
              rounded-full
            `}
          />
        </div>
      </div>
    </Link>
  );
}

function TeamMemberCard({ name, position, photo }) {
  return (
    <div className="relative p-6 bg-white/40 backdrop-blur-lg rounded-xl shadow-lg flex flex-col items-center text-center border border-transparent  transition-all duration-300">
      {/* Profile Image */}
      <img
        src={photo}
        alt={name}
        className="w-32 h-32 md:w-40 md:h-40 rounded-full object-cover mb-6 border-4 border-white shadow-lg"
      />

      {/* Name */}
      <h3 className="text-xl font-semibold text-gray-900 mb-2">{name}</h3>

      {/* Position */}
      <p className="text-sm text-gray-600 mb-4">{position}</p>

      {/* Matte Glass Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-blue-100 rounded-xl opacity-25"></div>

      {/* Soft Border for Elegance */}
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-blue-400 rounded-b-xl opacity-20"></div>
    </div>
  );
}

function Dashboard() {
  const dashboardCards = [
    {
      title: "Beneficiary Management",
      description:
        "Comprehensive profile tracking and insights for ward beneficiaries with advanced data management tools",
      to: "/beneficiaries",
      icon: Users,
      bgColor: "bg-blue-50",
      textColor: "text-blue-700",
    },
    {
      title: "Beneficiary Classification",
      description:
        "Advanced categorization and targeted support strategies using intelligent data analysis and segmentation",
      to: "/categorization",
      icon: ListOrdered,
      bgColor: "bg-green-50",
      textColor: "text-green-700",
    },
    {
      title: "SMS Service",
      description:
        "Automated SMS notifications and communication tools for efficient beneficiary engagement and support",
      to: "/sms",
      icon: MessageCircle,
      bgColor: "bg-yellow-50",
      textColor: "text-yellow-700",
    },
  ];

  const teamMembers = [
    {
      name: "Krishna Bahadur",
      position: "Ward Chairman",
      photo: "https://via.placeholder.com/150", // Replace with the actual photo URL
    },
  ];

  return (
    <div className="bg-gray-100 py-6 md:py-4 px-0 sm:px-6 lg:px-0">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="bg-white rounded-3xl shadow-2xl overflow-hidden mb-7">
          <div className="p-8 bg-gradient-to-r from-blue-400 to-blue-600 text-white">
            <div className="flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-8">
              <GovLogo />
              <div className="text-center md:text-left flex-grow">
                <h2 className="text-3xl font-bold mb-3">
                  Mahakali Irrigation Project
                </h2>
                <p className="text-lg opacity-80 mb-4">Management System</p>
                <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
                  <span className="bg-white bg-opacity-20 px-4 py-2 rounded-full text-sm">
                    Karnali
                  </span>
                  <span className="bg-white bg-opacity-20 px-4 py-2 rounded-full text-sm">
                    Fiscal Year 2080/81
                  </span>
                </div>
              </div>
              <FlagOfNepal />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
          {/* Dashboard Cards Section: Takes 2 columns for each card */}
          <div className="md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-4">
            {dashboardCards.map((card) => (
              <DashboardCard key={card.title} {...card} />
            ))}
          </div>

          {/* Team Members Section: Takes 1 column per card */}
          <div className="md:col-span-1 grid grid-cols-1 gap-4">
            {teamMembers.map((member) => (
              <TeamMemberCard
                key={member.name}
                name={member.name}
                position={member.position}
                photo={member.photo}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
